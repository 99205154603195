import React from 'react';
import { Link } from 'react-router-dom';

import { Displayable, PERMESSI, FLAGS_SMISTATORE, STATO_SHORTLIST, FONTE } from '../globals';
import { Permessi as Permesso, Partecipante, FlagSmistatoreChar, StatoShortlist, Fonte, Risposta } from '../api/types';
import { displayPartecipante } from '../utils/cache';
import { sentry_log } from './sentry'
import { MdMath } from '../components'
import { Competenza } from '../api/auto-types';
import { COMPETENZA_TIPOLOGIA } from '../globals/auto-enums';



/**
 * Prende una ministringa che è l'id di un displayable
 * e restituisce il nome guardabile
 */
export function displayDisplayable(obj: string, family: Displayable[]): string {
  const match = family.find((item) => item.id === obj);
  if (match) {
    return match.display;
  } else {
    console.warn("Non matchato il seguente: " + obj);
    return "";
  }
}


export function displayPermesso(p: Permesso) {
  return displayDisplayable(p, PERMESSI);
}

export function displayFlagSmistatore(p: FlagSmistatoreChar) {
  return displayDisplayable(p, FLAGS_SMISTATORE);
}

export function displayFonte(p: Fonte) {
  return displayDisplayable(p, FONTE)
}

export function displayColorePermesso(p: Permesso) {
  let color;
  switch (p) {
    case 'a':
      color = "danger"; break;
    case 'm':
      color = "warning"; break;
    case 's':
      color = "success"; break;
    case 'e':
      color = "primary"; break;
    case 'x':
      color = "concrete"; break;
    default:
      color = "secondary"; break;
  }
  return color;
}


export function displayBadgePermesso(p: Permesso) {
  const titolo = displayDisplayable(p, PERMESSI);
  const color = displayColorePermesso(p);
  const classes = "badge badge-" + color;
  return (<span className={classes}> {titolo} </span>)
}



export function displayPartecipanteBadge(p: Partecipante) {
  let name_classes = "px-1 mb-2 text-"
  let extra = displayColorePermesso(p.permessi);
  name_classes += extra;
  return (
    <Link to={`/utente/${p.id}/`}>
      <span className={name_classes}>
        {displayBadgePermesso(p.permessi)} {displayPartecipante(p.id)}
      </span>
    </Link>
  )
}


export function displayStatoShortlist(s: StatoShortlist) {
  return displayDisplayable(s, STATO_SHORTLIST);
}


export function displayBadgeStatoShortlist(s: StatoShortlist) {
  const classes = "badge badge-"
  let extra;
  switch (s) {
    case 'a': extra = "success"; break;
    case 'r': extra = "danger"; break;
    case 'p': extra = "warning"; break;
    default:
      sentry_log(new Error("Chiamato displayBadgeStatoShortlist con valore insensato: ${s}"));
      extra = "danger"; break;
  }
  return <span className={classes + extra}>{displayStatoShortlist(s)}</span>
}


export const displayRisposta = (r: Risposta) => {
  const extra = r.risposta_exp != 0 ? `e${r.risposta_exp}` : "";
  const risposta_tex = `$\\SI{${r.risposta}${extra}}{}$`;
  return <MdMath source={risposta_tex} />
}


export const displayCompetenza = (c: Competenza) => {
  const tipo = displayDisplayable(c.tipologia, COMPETENZA_TIPOLOGIA);
  return `${tipo} - ${c.nome} (${c.descrizione})`
}
