import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
// @ts-ignore
import StarRatings from 'react-star-ratings';

import {
  MdMath, Partecipante, CategoriaMap, CategoriaEventoMap, PartecipantiMap, AllegatoMap,
  BottoneVersioniProblema, LinkProblema, BottoneFlagSmistatore, LoadingCircle, Spoiler,
  LaTeX, SearchProblema, RealTeXLoad,
} from '../components'
import {
  nice_date, displayPartecipante, displayFlagSmistatore,
  sentry_log, displayFonte, displayCompetenza
} from '../utils';
import { ThreadDettaglio } from '../api/types';
import { canEditProblema, canAssegnareProblemi, star_style, canSetSmistatoreFlag } from '../globals';
import {
  post_bellezza, post_difficolta,
  get_bellezza, get_difficolta, build_tex_problema, build_tex_soluzione,
} from '../api';
import { useLogin, useThread, useAllegato, useEvento, useCategoriaEvento, useShortlist, useCompetenza } from '../reducers'


interface ownProps {
  thread: ThreadDettaglio,
}


const Problema = (props: ownProps) => {
  const { thread } = props;
  const { problema } = thread;

  const login = useLogin();
  const thread_list = useThread(props);
  const shortlists = useShortlist(props);
  const competenze = useCompetenza(props);
  useAllegato(props);
  useCategoriaEvento(props);
  const eventi = useEvento(props);
  const [bellezza, setBellezza] = useState(0);
  const [difficolta, setDifficolta] = useState(0);
  const [hideProblema, setHideProblema] = useState(false);
  const [hideSoluzione, setHideSoluzione] = useState(false);

  useEffect(() => {
    const pk = thread.problema.id;
    get_bellezza({ partecipante: login.user.id, problema: pk }).then(ans => ans.length > 0 && setBellezza(ans[0].voto))
    get_difficolta({ partecipante: login.user.id, problema: pk }).then(ans => ans.length > 0 && setDifficolta(ans[0].voto))
  }, [props.thread.problema.id])

  const last_versione = problema.versioni[problema.versioni.length - 1];
  const modificato = (problema.versioni.length > 1)
  const data_pubblicazione = nice_date(new Date(problema.data_pubblicazione));
  const data_modifica = nice_date(new Date(last_versione.data));
  const modificato_da_altri = last_versione.autore != problema.propositore;
  const extra = modificato_da_altri ? ` da ${displayPartecipante(last_versione.autore)}` : "";
  const testo_data = modificato ?
    `Pubblicato: ${data_pubblicazione}. Modificato l'ultima volta${extra}: ${data_modifica}` :
    `${data_pubblicazione}`;
  const bottone_modifica = ((login.user.id == problema.propositore) ||
    canEditProblema(login.user, problema, shortlists.map(s => s.shortlist))) ?
    <Link to={`/thread/problema/edit/${thread.id}/`}>
      <button className="btn btn-warning mr-3" title="Edit">
        <i className="fa fa-edit" />
      </button>
    </Link> : null;
  const change_rating = (post_function: typeof post_bellezza | typeof post_difficolta, name: "difficolta" | "bellezza") => (newrating: number) => {
    post_function({ problema: problema.id, voto: newrating })
      .then(risposta => {
        const setVal = name === "difficolta" ? setDifficolta : setBellezza;
        setVal(risposta.voto);
      })
      .catch(error => sentry_log(error));
  }
  const bottone_assegna = canAssegnareProblemi(login.user.permessi) ?
    <Link to={`/thread/problema/${thread.id}/assegna/`}>
      <button className="btn btn-belize-hole mr-3" title="Assegna il problema">
        <i className="fa fa-hand-point-right" />
      </button>
    </Link>
    : null;
  const bottone_versioni = modificato ?
    <BottoneVersioniProblema problema={problema} />
    : null;
  const bottone_voti = (
    <Link to={`/thread/problema/${thread.id}/voti/`}>
      <button className="btn btn-success mr-3" title="Voti assegnati al problema">
        <i className="fa fa-star" />
      </button>
    </Link>
  );
  const bottone_flag_smistatore = canSetSmistatoreFlag(login.user.permessi) ?
    <BottoneFlagSmistatore
      thread={thread}
      thread_list={thread_list}
    />
    : null;
  const bottone_ricerca = <SearchProblema id={problema.id} />
  const short_comparso = shortlists.filter(short => short.shortlist.versioni[short.shortlist.versioni.length - 1].lista_problemi.includes(thread.problema.id))
  return (
    <div className="card my-1">
      <div className="card-header">
        <div className="conatiner">
          <h3>#{thread.id} - {thread.titolo}{thread.titolo != problema.titolo && ` [${problema.titolo}]`}</h3>
          {thread.problema.evento_assegnato &&
            <h5><i className="text-danger mr-1 fa fa-times" /> {`Problema assegnato nell'evento ${eventi.find(ev => ev.id == thread.problema.evento_assegnato)?.titolo}`}</h5>}
          {short_comparso.length > 0 &&
            <h5><i className="text-warning mr-1 fa fa-exclamation-triangle" />Problema presente nelle shortlist: {short_comparso.map(short => <Link to={`/shortlist/${short.id}/`}><span className="mr-2">{short.titolo}</span></Link>)}</h5>}

          <div className="row pb-1">
            <div className="col-6">
              <h6>Categorie: {<CategoriaMap categorie={problema.categoria} />}</h6>
            </div>
            <div className="col-6">
              <h6>Assegnato a:</h6>
              <div className="overflow-auto" style={{ maxHeight: "120px" }}><p>{<PartecipantiMap partecipanti={problema.correttori_assegnati} />}</p></div>
            </div>
          </div>
          <div className="row pb-1">
            <div className="col-6">
              <h6>Proposto per gli eventi:</h6>
              <div className="overflow-auto" style={{ maxHeight: "120px" }}><p>{<CategoriaEventoMap cats={problema.eventi_proposto} />}</p></div>
            </div>
            <div className="col-6">
              <h6>Corretto da:</h6>
              <div className="overflow-auto" style={{ maxHeight: "120px" }}><p>{<PartecipantiMap partecipanti={problema.correttori_eseguito} />}</p></div>
            </div>
          </div>
          <div className="row">
            {problema.flags_smistatore &&
              <div className="d-flex justify-content-between card">
                <div className="card-body">
                  <h6>Problema marcato come {displayFlagSmistatore(problema.flags_smistatore.flag)} da {displayPartecipante(problema.flags_smistatore.ultimo_revisore)}.</h6>
                  {problema.flags_smistatore.messaggio_extra &&
                    <p>{problema.flags_smistatore.messaggio_extra}</p>}
                  {(problema.flags_smistatore.flag == 'd') &&
                    <h6>Problemi simili: {problema.flags_smistatore.problemi_simili.map(item => {
                      const simile = thread_list.find(i => i.problema.id == item);
                      if (!simile) {
                        return <LoadingCircle />
                      }
                      return <LinkProblema thread_list={[simile,]} pk={simile.id} />;
                    })}</h6>
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="container-fluid">
          <div className="row pt-2">
            <div className="col-lg-9 pl-4 d-flex align-items-start flex-column" style={{ overflowX: "auto" }}>
              {!hideProblema &&
                <LaTeX text={last_versione.testo} />
              }
              <RealTeXLoad id={thread.id} api={() => build_tex_problema(thread.id, {})} callback={() => setHideProblema(true)} />
              {problema.commenti_correttori &&
                <div className="row pt-2 card px-2 py-2 my-1">
                  <h6 className="card-title"><i>Note per gli organizzatori</i></h6>
                  <div className="card-body">
                    <MdMath source={problema.commenti_correttori} />
                  </div>
                </div>
              }
              <dl>
                <dt>Fonte:</dt>
                <dd>[{displayFonte(problema.fonte)}] {problema.fonte_verboso}</dd>
              </dl>
              {problema.soluzione_spiegata &&
                <div className="row pt-2 card px-2 py-2 my-1 w-100">
                  <h6 className="card-title"><i>Soluzione proposta</i></h6>
                  <div className="card-body">
                    <Spoiler big>
                      {!hideSoluzione &&
                        <LaTeX text={problema.soluzione_spiegata} />
                      }
                      <RealTeXLoad id={thread.id} api={() => build_tex_soluzione(thread.id, {})} callback={() => setHideSoluzione(true)} />
                    </Spoiler>
                  </div>
                </div>
              }
              {problema.competenze?.length > 0 &&
                <div className="row pt-2 card px-2 py-2 my-1 w-100">
                  <h6 className="card-title"><i>Competenze necessarie</i></h6>
                  <div className="card-body">
                    <Spoiler big>
                      <ul>
                        {problema.competenze.map(comp => {
                          const competenza = competenze.find(c => c.id === comp);
                          if (!competenza) return <LoadingCircle />;
                          return (
                            <li>{displayCompetenza(competenza)}</li>
                          )
                        })}
                      </ul>
                    </Spoiler>
                  </div>
                </div>

              }
              <div className="row mb-4">
                <div className="d-inline-flex flex-row">
                  <AllegatoMap allegati={problema.allegati} />
                </div>
              </div>
              <div className="row mt-auto">
                <div className="d-inline-flex flex-row">
                  {bottone_modifica}
                  {bottone_assegna}
                  {bottone_versioni}
                  {bottone_voti}
                  {bottone_flag_smistatore}
                  {bottone_ricerca}
                </div>
              </div>
            </div>
            <div className="col-lg-3 no-gutters">
              <Partecipante partecipante={problema.propositore} />
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer py-1">
        <small>{testo_data}</small>
      </div>
      <div className="container">
        <div className="row d-flex justify-content-between py-2">
          <span className="px-2">
            Bellezza:
            <StarRatings
              {...star_style}
              changeRating={change_rating(post_bellezza, "bellezza")}
              rating={bellezza}
            />
          </span>
          <span className="px-2">
            Difficoltà:
            <StarRatings
              {...star_style}
              changeRating={change_rating(post_difficolta, "difficolta")}
              rating={difficolta}
            />
          </span>
        </div>
        <div className="d-flex justify-content-end">
          <span>Ricorda di consultare la <Link to="/guida/difficolta/">guida</Link> alla difficoltà.</span>
        </div>
      </div>
    </div >
  );
}

export default Problema;
