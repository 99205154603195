import { WithId, Esportone, StructuredEsportone, useSelectorRefresh } from 'django-rest-react'
import {
  Evento, Categoria, Partecipante, ThreadLista, Allegato,
  ThreadShortlist, CategoriaEvento,
} from '../api/types';
import { Competenza } from '../api/auto-types';


export enum Reverse {
  CategoriaEvento = "categorieevento",
  Evento = "eventi",
  Categoria = "categorie",
  Partecipante = "partecipanti",
  Thread = "thread",
  Allegato = "allegati",
  Shortlist = "shortlist",
  Competenza = "competenze",
}

type AppState = any;

export const categoriaevento_all = new Esportone<CategoriaEvento>(
  "CATEGORIAEVENTO", () => "/api/problems/problemi/categoriaevento/", (state: AppState) => state[Reverse.CategoriaEvento]);
export const evento_all = new Esportone<Evento>(
  "EVENTO", () => "/api/problems/evento/", (state: AppState) => state[Reverse.Evento]);
export const categoria_all = new Esportone<Categoria>(
  "CATEGORIA", () => "/api/problems/categoria/", (state: AppState) => state[Reverse.Categoria]);
export const partecipanti_all = new Esportone<Partecipante>(
  "PARTECIPANTE", () => "/api/problems/partecipante/", (state: AppState) => state[Reverse.Partecipante]);
export const thread_all = new Esportone<ThreadLista>(
  "THREAD", () => "/api/problems/problemi/", (state: AppState) => state[Reverse.Thread]);
export const allegato_all = new Esportone<Allegato>(
  "ALLEGATO", () => "/api/problems/allegato/", (state: AppState) => state[Reverse.Allegato]);
export const shortlist_all = new Esportone<ThreadShortlist>(
  "SHORTLIST", () => "/api/problems/shortlist/", (state: AppState) => state[Reverse.Shortlist]);
export const competenze_all = new Esportone<Competenza>(
  "COMPETENZA", () => "/api/problems/problemi/competenza/", (state: AppState) => state[Reverse.Competenza]);


type ReverseMap = {
  [key: string]: Esportone<WithId> | StructuredEsportone<WithId>
}

export const reducers_map: ReverseMap = {
  [Reverse.CategoriaEvento]: categoriaevento_all,
  [Reverse.Evento]: evento_all,
  [Reverse.Categoria]: categoria_all,
  [Reverse.Partecipante]: partecipanti_all,
  [Reverse.Thread]: thread_all,
  [Reverse.Allegato]: allegato_all,
  [Reverse.Shortlist]: shortlist_all,
  [Reverse.Competenza]: competenze_all,
}



export const useCategoriaEvento: (props: any) => CategoriaEvento[] = props => useSelectorRefresh(props, Reverse.CategoriaEvento);
export const useCategoria: (props: any) => Categoria[] = props => useSelectorRefresh(props, Reverse.Categoria);
export const useEvento: (props: any) => Evento[] = props => useSelectorRefresh(props, Reverse.Evento);
export const usePartecipante: (props: any) => Partecipante[] = props => useSelectorRefresh(props, Reverse.Partecipante);
export const useThread: (props: any) => ThreadLista[] = props => useSelectorRefresh(props, Reverse.Thread);
export const useAllegato: (props: any) => Allegato[] = props => useSelectorRefresh(props, Reverse.Allegato);
export const useShortlist: (props: any) => ThreadShortlist[] = props => useSelectorRefresh(props, Reverse.Shortlist);
export const useCompetenza: (props: any) => Competenza[] = props => useSelectorRefresh(props, Reverse.Competenza);


let reducers_veri: ReverseMap = {};
Object.keys(reducers_map).forEach((key: Reverse) => {
  reducers_veri[key] = reducers_map[key].objectReducer.bind(reducers_map[key]);
});

export { reducers_veri };
