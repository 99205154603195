
import store from '../store';
import { evento_all, categoria_all, partecipanti_all, thread_all, allegato_all, categoriaevento_all } from '../reducers'
import { Esportone } from 'django-rest-react'
import { sentry_log } from '../utils'

interface WorkerEvent extends Event {
  data: string
}


interface CachedMessage {
  origin: string,
  json: {
    results: any
  }
}



const cached_response_handler = (message: CachedMessage) => {
  const url = new URL(message.origin);
  let page = null;
  // @ts-ignore
  for (const [key, value] of url.searchParams) {
    if (key === "page") {
      page = value;
      break;
    }
  }
  const callback = (cusu: Esportone<any>) => cusu.update_object_action(message.json.results);

  switch (url.pathname) {
    case "/api/problems/evento/":
      store.dispatch(callback(evento_all)); break;
    case "/api/problems/categoria/":
      store.dispatch(callback(categoria_all)); break;
    case "/api/problems/partecipante/":
      store.dispatch(callback(partecipanti_all)); break;
    case "/api/problems/problemi/":
      store.dispatch(callback(thread_all)); break;
    case "/api/problems/allegato/":
      store.dispatch(callback(allegato_all)); break;
    case "/api/problems/problemi/categoriaevento/":
      store.dispatch(callback(categoriaevento_all)); break;
    default:
      sentry_log(new Error(`Ricevuto messaggio di cache dal sw con pathname sbagliato: ${url}`))
  }
}


export const service_worker_handler = (event: WorkerEvent) => {
  const message = JSON.parse(event.data);
  switch (message.header) {
    case "cached_response":
      return cached_response_handler(message.body);
    default:
      console.warn("Received wrong header from service worker", message.header);
      break;
  }
  /* event.ports[0].postMessage("Client 1 Says 'Hello back!'"); */
}
